@use "sass:color";@use 'roedl' as *;.TenantChangeProcess {
  margin: 0;

  .tenant-change-step {
    height: 100%;
    width: 100%;
    border: 1px solid $custom-light-grey;
    border-bottom-style: none;
    padding: 12px;
  }

  .tenant-change-step:first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  .tenant-change-step:last-child {
    border-bottom-style: solid;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .step-title {
    margin: 0;
    font-weight: 600;
    font-size: 1.4em;
    line-height: 0.9;
    display: flex;
    justify-content: space-between;

    .step-done-icon {
      font-size: 1.5rem;
      color: $brand-color;
    }
  }

  .step-title-inactive {
    color: $custom-grey;
  }

  .step-action-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: 10px;
  }
}

@use "sass:color";@use 'roedl' as *;.EditConfigurationModal {
  .historical-data-import-with-submit {
    display: flex;
    flex-flow: column;
    align-items: center;
    margin-bottom: 20px;

    .history-action-btns-container {
      display: flex;
      flex-direction: column;
      row-gap: 1em;
      width: 192px;
    }
  }
}

@use "sass:color";@use 'roedl' as *;.EnergyDataTemplateDownload {
  .explanation {
    margin-bottom: 2em;
  }

  .template-download {
    display: flex;
    flex-direction: column;
    align-items: center;

    #template-date-range-picker {
      .DateRangePickerInput {
        background: none;

        .DateInput_1:first-of-type {
          border-radius: 10px 0 0 10px;
        }

        .DateInput_1:last-of-type {
          border-radius: 0 10px 10px 0;
        }

        .DateRangePickerInput_arrow {
          color: #fff;
          font-weight: bold;
        }
      }
    }

    $template-controls-width: 217px;

    #template-meter-selection {
      margin-top: 15px;
      width: $template-controls-width;
    }

    #template-unit-selection {
      margin-top: 4px;
      width: $template-controls-width;
    }

    .SpinButton {
      margin-top: 8px;
      width: $template-controls-width;
    }
  }
}

.energy-data-template-download-confirmation-body p:last-child {
  margin: 0;
}

@use "sass:color";@use 'roedl' as *;.GuaranteeOfOriginGenerationView {
  .controls {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 40px;

    .react-select {
      min-width: 215px;
    }
  }
}

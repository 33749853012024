@use "sass:color";@use 'roedl' as *;@use "../../../../global" as *;

.variant-row {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  min-height: 25px;

  @include shadow-hover;

  .variant-name {
    display: inline-block;
    min-height: 20px;

    div {
      display: inline;
    }
  }

  .icons {
    display: inline-flex;
    justify-content: flex-end;

    .la {
      padding: 0 0.1rem;
    }
  }
}

@use "sass:color";@use 'roedl' as *;.TodoConfirmationWidget {
  .explanation-text {
    margin-bottom: 1.3rem;
  }

  .todo-section {
    margin-bottom: 1.5rem;

    .todo-header {
      font-weight: initial;
    }
  }

  .todo-list {
    display: flex;
    flex-flow: column;

    .todo {
      display: flex;
      flex-flow: row;
      margin-bottom: 5px;
      min-height: 35px;

      .todo-toggle {
        padding-top: 8px;
        padding-right: 3px;
      }

      .todo-label {
        display: flex;
        flex-flow: column;
        flex-grow: 1;
        padding-top: 7px;
        padding-right: 20px;
        cursor: pointer;

        .todo-details {
          padding: 10px;
          margin-bottom: 20px;
        }
      }

      .todo-clarify-button {
        display: flex;
        align-items: center;
        margin-right: 0.25rem;
      }

      .todo-switch {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 210px;
        height: 45px;

        .dropdown-toggle {
          min-width: 205px;

          &.dropdown-decision-confirmed {
            color: $status-confirmed-color;
            background-color: $status-confirmed-background;
            border-color: $status-confirmed-border;
          }

          &.dropdown-decision-confirmed-finished {
            color: $status-confirmed-finished-color;
            background-color: $status-confirmed-finished-background;
            border-color: $status-confirmed-finished-border;
          }

          &.dropdown-decision-rejected {
            color: $status-rejected-color;
            background-color: $status-rejected-background;
            border-color: $status-rejected-border;
          }

          &.dropdown-decision-unclear {
            color: $status-unclear-color;
            background-color: $status-unclear-background;
            border-color: $status-unclear-border;
          }
        }
      }
    }
  }
}

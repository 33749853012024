@use "sass:color";@use 'roedl' as *;.site-tasks-container {
  height: 100%;
}

.site-task-container-fade-in {
  animation: slide-in-right-left-animation 1s forwards;
}

.site-task-container-fade-out {
  animation: slide-out-left-right-animation 1s forwards;
}

.site-tasks-header-collapsed {
  transform: rotate(270deg);
  position: relative;
  top: 160px;
  right: -20px;
  width: -0px;
  white-space: nowrap;
}

@keyframes slide-out-left-right-animation {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(95%);
  }
}

@keyframes slide-in-right-left-animation {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

.site-task-collapse {
  width: 100%;
}

.circle-arrow {
  position: relative;
  display: inline-block;
  font-size: 3rem;
  color: $brand-color;
  cursor: pointer;
}

.site-tasks-header-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.site-tasks-header-text {
  margin: 0;
  margin-left: 10px;
  font-weight: 700;
  color: $node-energy-black;
}

.red-circle {
  position: absolute;
  right: -4px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: $danger-color;
}

.circle-and-icon-container {
  position: relative;
}

@use "sass:color";@use 'roedl' as *;.DropdownFilter {
  &.show {
    .dropdown-toggle {
      &.dropdown-toggle-with-search {
        padding-left: 0;
      }

      .filter-dropdown-text {
        border-right: none;
      }
    }

    .dropdown-menu {
      padding: 0;

      .scrollable-menu-items {
        max-height: 350px;
        padding: 0.5rem 0;
        overflow: auto;

        .dropdown-item {
          padding: 0.7rem 1.2rem;

          &:active {
            background-color: unset;
          }

          .checkbox-item {
            pointer-events: none;
          }
        }
      }
    }

    .apply-button {
      width: 100%;
    }
  }
}

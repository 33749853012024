@use "sass:color";@use 'roedl' as *;.Contracts {
  .contracts-header {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .contracts-body {
    margin-top: 30px;
  }

  .parkabrechnung-portlet {
    .m-portlet__head-title {
      width: 100%;
    }

    .m-portlet__body {
      display: none;
    }
  }
}

@use "sass:color";@use 'roedl' as *;@use "../../../global.scss" as *;

.TodoModal {
  max-width: calc(100vw - 65px);

  @media screen and (min-width: 1265px) {
    max-width: 1200px;
  }

  .nav-item {
    cursor: pointer;
  }

  .overview {
    .todo-property {
      margin-bottom: 10px;
    }

    .todo-property-value,
    .regulatory-duty-rows,
    .user-defined-description {
      margin-left: 10px;
    }

    .responsible-cell,
    .status-cell {
      select {
        width: 200px;
      }
    }
  }

  .documents {
    display: flex;
    flex-flow: column;

    h6 {
      margin-bottom: 1.5rem;
    }

    .ReactTable .rt-th,
    .ReactTable .rt-td {
      white-space: unset;
    }
  }

  .no-data-component {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;

    p {
      margin: 0;
    }
  }
}

.WhichUserModal {
  .modal-body {
    text-align: center;
  }

  .which-user-modal-buttons {
    display: flex;
    justify-content: space-around;
    margin-top: 30px;
  }
}

@use "sass:color";@use 'roedl' as *;.EnergyDataAvailabilityPage {
  .year-picker {
    display: flex;
    align-items: center;
    gap: var(--mantine-spacing-sm);

    .mantine-InputWrapper-label {
      margin: 0;
      font-weight: bold;
    }
  }
}

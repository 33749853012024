@use "sass:color";@use 'roedl' as *;.payments.controls {
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
}

.payments-table {
  .help-icon {
    -webkit-text-stroke: 1px white;
    font-size: 1.3rem;
  }

  .payments-cell {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .payments-cell-value {
      margin-right: 10px;
    }
  }

  .payments-number-cell {
    display: flex;
    align-items: center;

    .import-symbol {
      margin-left: 3px;
      color: darkblue;
      cursor: pointer;
    }
  }

  .no-data-component {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;

    p {
      margin: 0;
    }
  }

  .FormField {
    margin: 0;
  }

  .year {
    display: flex;
    align-items: center;
    height: 100%;
    max-width: 75px;
  }

  .month {
    display: flex;
    align-items: center;
    height: 100%;
    max-width: 100px;
  }

  .directDeliverySharePredictionCell {
    min-width: 120px;
  }

  .deliveryAmountPredictionThisYearCell {
    min-width: 120px;
  }

  .discountPaymentsCell {
    min-width: 120px;
  }

  .estimatedAdvancePaymentsCell {
    min-width: 150px;
  }
}

@use "sass:color";@use 'roedl' as *;.CustomTextElementPopup {
  position: absolute;
  top: 200px;
  left: 200px;
  z-index: 1;
  max-width: 280px;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  padding: 9px 14px;
  text-align: left;
  word-wrap: break-word;
  line-height: 20px;
  .text-config-controls {
    margin-bottom: 10px;
    .m-menu__hor-arrow {
      font-size: 1.32em;
    }
    .dropdown-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .btn {
        width: 90px;
        font-size: 0.9em;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
    .text-config-modal-font-size-dropdown-container {
      margin-bottom: 10px;

      .text-config-modal-font-size-description {
        margin-right: 10px;
      }
    }
  }
  .small {
    font-size: 80%;
    font-weight: 400;
  }
  .delete-disabled {
    color: $custom-light-grey;
  }
  p {
    margin: 0;
  }
}

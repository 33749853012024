@use "sass:color";@use 'roedl' as *;.ListView {
  footer {
    display: flex;
    flex-flow: row;
    justify-content: center;
    width: 100%;
    margin-top: 20px;

    label {
      display: flex;
      align-items: center;
      margin: 0;
    }
  }

  input {
    margin-left: 5px;
    margin-right: 10px;
  }

  button {
    margin: 0 2px;
  }
}

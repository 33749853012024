@use "sass:color";@use 'roedl' as *;.netzbezug-data-site-input-container {
  & .drittlieferung {
    display: flex;
    gap: 2rem;
  }
}

.netzbezug-upper-right-controls-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.energy-data-loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.netzbezug-data-price-input {
  max-width: 300px;
}

.drittlieferung-data-price {
  margin: 0.5rem;
  border: 1px solid $custom-light-grey;
  border-radius: 5px;
  padding: 1rem;
}
.drittlieferung-data-price-input {
  display: grid;
  grid-template-columns: 2fr 2fr 1fr;
  gap: 1rem;
}

@use "sass:color";@use 'roedl' as *;.preview-invoices-link-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 14px;
  border-bottom: 1px solid $custom-light-grey;
  border-top: 1px solid $custom-light-grey;
}

.preview-invoices-modal {
  width: 130%;
}

.preview-invoices-link-name {
  font-weight: 500;
}

.preview-invoices-description-text {
  margin-bottom: 50px;
}

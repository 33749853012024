@use "sass:color";@use 'roedl' as *;.section-title-row {
  font-size: 15px;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
}
.col-header {
  padding-top: 0.3em;
  padding-bottom: 0.3em;
}
.grey-column {
  background-color: $custom-grey;
}

@use "sass:color";@use 'roedl' as *;.popover-dropdown {
  p {
    margin: 0;
  }

  max-width: 450px;
  border-color: $custom-light-grey;
  color: $status-rejected-color;
  font-size: 0.875rem;
  font-weight: 400;

  .mantine-Popover-arrow {
    border-color: $custom-light-grey;
  }
}

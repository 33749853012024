@use "sass:color";@use 'roedl' as *;.ChangeableFieldWidget {
  .save-cancel-buttons {
    display: flex;
    justify-content: flex-end;

    & > :not(:last-child) {
      margin-right: 0.25rem !important;
    }

    & > :not(:first-child) {
      margin-left: 0.25rem !important;
    }
  }
}

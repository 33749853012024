@use "sass:color";@use 'roedl' as *;.section {
  margin-top: 4rem;
  .section-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;

    h5 {
      margin-bottom: 2rem;
      font-weight: 400;
    }
  }
}

@use "sass:color";@use 'roedl' as *;.ChangeableParticipationField {
  .label-and-button {
    display: flex;
    align-items: center;

    .label-container {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-grow: 1;
      margin: 0 0.5rem 0 0;
      border-color: #ebedf2;
      border-radius: 4px;
      border-style: solid;
      border-width: 1px;
      min-height: 38px;
      cursor: pointer;

      .toggle-icon {
        border-color: #ebedf2;
        border-style: solid;
        border-width: 0 1px 0 0;
        min-height: 38px;
        width: 38px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 0.5rem 0 0;
      }
    }

    .label-button {
      margin-left: auto;
    }
  }

  .prices-edit,
  .loading-card {
    margin-top: 0.5rem;
  }
}

@use "sass:color";@use 'roedl' as *;@use "../../../../global.scss" as *;

.SiteListElement {
  @include shadow-hover;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  min-height: 25px;

  .site-name-container {
    display: flex;
    flex-direction: row;
    .site-name {
      width: 30ch;
    }
    mark {
      padding: 0;
    }
  }

  .item-name {
    display: inline-block;
    min-height: 20px;

    div {
      display: inline;
    }
  }

  .icons {
    display: inline-flex;
    justify-content: flex-end;

    .la {
      padding: 0 0.1rem;
    }
  }
}

@use "sass:color";@use 'roedl' as *;.OptiNodeHelpIcon {
  &:hover {
    text-decoration: none;
  }

  i {
    color: #000;
    font-size: 28px;
    border-radius: 6px;
    padding: 3px;
    transition:
      color,
      background-color 0.3s ease-in-out;

    &:hover {
      cursor: pointer;
      background-color: $brand-color;
      color: white;
    }
  }
}

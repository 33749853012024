@use "sass:color";@use 'roedl' as *;.Paragraph6CreditsTable {
  .credits-search-bar {
    margin-bottom: 32px;

    .credits-search-bar-input {
      width: 500px;
    }
  }

  .ReactTable .rt-tr {
    align-items: center;
  }

  .FormField {
    margin-bottom: 0;
  }

  .credit-delete-button.disabled .la-trash {
    filter: grayscale(100%);
  }

  .credit-delete-button {
    padding: 0;
  }

  .react-select__control {
    height: 32px;
    min-height: 32px;
  }

  .invoice-state-readytosend .react-select__control {
    background-color: $info-color;
  }

  .invoice-state-sent .react-select__control {
    background-color: $warning-color;
  }

  .invoice-state-received .react-select__control,
  .invoice-state-paid .react-select__control {
    background-color: $success-color;
  }

  .invoice-state-cancelled {
    background-color: $danger-color;
    display: block;
    color: white;
    border: 1px solid $input-border-color;
    padding: 5px 10px;
    border-radius: 4px;
    text-align: center;
  }
}

@use "sass:color";@use 'roedl' as *;.Todos {
  .filter-select {
    width: 100%;
  }
  .download-selected-documents-button-container {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 15px;
    align-items: center;

    .download-selected-documents-button-label {
      font-weight: 500;
      margin: 0;
      margin-right: 10px;
    }
  }
  .rt-tr > :first-child.rt-td {
    white-space: initial;

    .label-cell-text {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  .todo-checkbox {
    & + span {
      margin-top: -5px;
      margin-left: 1px;
      background-color: #fff;
    }
  }

  .status-cell select,
  .responsible-cell select {
    width: 100%;
    height: 24px;
  }

  .clickable-cell {
    cursor: pointer;
  }

  .header-wrapper {
    display: flex;
    justify-content: center;

    .header-text {
      max-width: calc(100% - 32px);
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .sorting-icon {
      position: absolute;
      top: 4px;
      right: 4px;
      color: white;
    }
  }

  .-sort-asc,
  .-sort-desc {
    -webkit-box-shadow: none !important; // override react-table
    box-shadow: none !important; // override react-table
  }

  .ReactTable {
    .rt-resizer {
      width: 14px; // override react-table to make a smaller resize control
      right: -7px;
    }

    .due-date-cell {
      text-align: center;
    }

    .rt-th {
      overflow: initial;

      .select-all-checkbox {
        & + span {
          margin-top: -5px;
          margin-left: 1px;
          background-color: #fff;
        }
      }

      .btn-group {
        width: 100%;
        height: 28px;

        .dropdown-toggle {
          width: 100%;
          padding: 0.35rem 16px 0.35rem 1rem;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .filter-dropdown-text {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        &.DropdownFilter {
          &.show {
            .dropdown-toggle {
              &.dropdown-toggle-with-search {
                padding-left: 0;
              }
            }
          }
        }
      }
    }

    .rt-tr {
      align-items: center;
    }
  }

  .ReactTable .rt-thead.-filters {
    .DateRangePickerInput {
      padding: 0;
      background-color: #fff;
      border-radius: 3px;
    }

    .DateRangePickerInput_arrow {
      margin-left: 8px;
      margin-right: 0px;
    }

    .DateInput {
      border-radius: 3px;
    }

    .DateInput_input {
      text-align: center;
      border: none;
    }
  }

  .checkbox-item {
    pointer-events: none;
  }

  .no-data-component {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;

    p {
      margin: 0;
    }
  }
}

@use "global-fonts" as *;

/* ibm-plex-sans-300 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 300;
  src:
    url("./fonts/smarendo/ibm-plex-sans-v14-latin-300.woff2") format("woff2"),
    /* Chrome 36+, Opera 23+, Firefox 39+ */
      url("./fonts/smarendo/ibm-plex-sans-v14-latin-300.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* ibm-plex-sans-300italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "IBM Plex Sans";
  font-style: italic;
  font-weight: 300;
  src:
    url("./fonts/smarendo/ibm-plex-sans-v14-latin-300italic.woff2")
      format("woff2"),
    /* Chrome 36+, Opera 23+, Firefox 39+ */
      url("./fonts/smarendo/ibm-plex-sans-v14-latin-300italic.woff")
      format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* ibm-plex-sans-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 400;
  src:
    url("./fonts/smarendo/ibm-plex-sans-v14-latin-regular.woff2")
      format("woff2"),
    /* Chrome 36+, Opera 23+, Firefox 39+ */
      url("./fonts/smarendo/ibm-plex-sans-v14-latin-regular.woff")
      format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* ibm-plex-sans-italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "IBM Plex Sans";
  font-style: italic;
  font-weight: 400;
  src:
    url("./fonts/smarendo/ibm-plex-sans-v14-latin-italic.woff2") format("woff2"),
    /* Chrome 36+, Opera 23+, Firefox 39+ */
      url("./fonts/smarendo/ibm-plex-sans-v14-latin-italic.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* ibm-plex-sans-500 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 500;
  src:
    url("./fonts/smarendo/ibm-plex-sans-v14-latin-500.woff2") format("woff2"),
    /* Chrome 36+, Opera 23+, Firefox 39+ */
      url("./fonts/smarendo/ibm-plex-sans-v14-latin-500.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* ibm-plex-sans-500italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "IBM Plex Sans";
  font-style: italic;
  font-weight: 500;
  src:
    url("./fonts/smarendo/ibm-plex-sans-v14-latin-500italic.woff2")
      format("woff2"),
    /* Chrome 36+, Opera 23+, Firefox 39+ */
      url("./fonts/smarendo/ibm-plex-sans-v14-latin-500italic.woff")
      format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* ibm-plex-sans-600 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 600;
  src:
    url("./fonts/smarendo/ibm-plex-sans-v14-latin-600.woff2") format("woff2"),
    /* Chrome 36+, Opera 23+, Firefox 39+ */
      url("./fonts/smarendo/ibm-plex-sans-v14-latin-600.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* ibm-plex-sans-600italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "IBM Plex Sans";
  font-style: italic;
  font-weight: 600;
  src:
    url("./fonts/smarendo/ibm-plex-sans-v14-latin-600italic.woff2")
      format("woff2"),
    /* Chrome 36+, Opera 23+, Firefox 39+ */
      url("./fonts/smarendo/ibm-plex-sans-v14-latin-600italic.woff")
      format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* ibm-plex-sans-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 700;
  src:
    url("./fonts/smarendo/ibm-plex-sans-v14-latin-700.woff2") format("woff2"),
    /* Chrome 36+, Opera 23+, Firefox 39+ */
      url("./fonts/smarendo/ibm-plex-sans-v14-latin-700.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* ibm-plex-sans-700italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "IBM Plex Sans";
  font-style: italic;
  font-weight: 700;
  src:
    url("./fonts/smarendo/ibm-plex-sans-v14-latin-700italic.woff2")
      format("woff2"),
    /* Chrome 36+, Opera 23+, Firefox 39+ */
      url("./fonts/smarendo/ibm-plex-sans-v14-latin-700italic.woff")
      format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@use "sass:color";@use 'roedl' as *;.Toastify__banner-toast {
  --toastify-toast-width: 640px;
}

.BannerToast {
  .toast-bodytext {
    margin: 0;
  }

  .toast-button-container {
    margin-top: 1rem;
    text-align: right;
  }
}

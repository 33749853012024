@use "sass:color";@use 'roedl' as *;.card {
  border: none;
}

.profile-picture {
  .pic {
    text-align: center;
    padding: 0 5px 0 0;

    .pic-wrapper {
      padding: 8px;
      display: inline-block;
      margin: 2rem auto;
      border: 2px solid #f4f5f8;
      -webkit-border-radius: 100%;
      -moz-border-radius: 100%;
      -ms-border-radius: 100%;
      -o-border-radius: 100%;
      border-radius: 100%;
    }

    img {
      text-align: center;
      width: 130px !important;
      height: 130px;
      margin: 0 auto !important;
      -webkit-border-radius: 100%;
      -moz-border-radius: 100%;
      -ms-border-radius: 100%;
      -o-border-radius: 100%;
      border-radius: 100%;
      object-fit: cover;
    }
  }

  .details {
    text-align: center;
    padding: 0;

    .name {
      display: block;
      padding: 0;
      font-size: 1.5rem;
      font-weight: 500;
      color: #1b1c1e;
    }

    .email {
      display: inline-block;
      padding: 6px 0 0 0;
      font-size: 1rem;
      color: #7b7e8a;

      :hover {
        color: #6f727d;

        ::after {
          border-bottom: 1px solid #6f727d;
          opacity: 0.3;
        }
      }
    }
  }
}

#div_id_image {
  .label {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
}

.custom-file {
  .custom-file-label {
    &:after {
      content: "Auswählen";
    }
  }
}

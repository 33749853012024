@use "sass:color";@use 'roedl' as *;.PasswordResetForm {
  margin-top: 3em;

  .SubmitBtnWrapper {
    text-align: center;
    margin-top: 2em;

    .PasswordSubmitBtn {
      width: 100%;
      border-radius: 10px;
    }
  }

  .PasswordRulesList {
    color: $custom-grey;
    padding-left: 1em;
    font-weight: 400;

    .PasswordRulesListItem {
      position: relative;

      .PasswordRulesCheckIcon {
        position: absolute;
        left: -20px;

        .SuccessCheck {
          color: $success-color;
        }

        .FailedCheck {
          color: $danger-color;
        }
      }
    }
  }
}

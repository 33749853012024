@use "sass:color";@use 'roedl' as *;.SiteSubMenu {
  .dropdown-menu {
    max-height: calc(100vh - 100px);
    overflow-y: scroll;

    .dropdown-item {
      max-width: 800px;
      overflow-x: hidden;
      text-overflow: ellipsis;
    }
  }
}

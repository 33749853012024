@use "sass:color";@use 'roedl' as *;.Accordion {
  .accordion-header {
    display: flex;
    align-items: center;
    height: 5.1rem;
    cursor: pointer;

    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;

    label {
      cursor: pointer;
    }

    .accordion-icon {
      font-size: 1.3rem;
      margin-right: 13px;
    }

    .accordion-header-text {
      font-size: 1.3rem;
      font-weight: 500;
    }
  }
}

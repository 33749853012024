@use "sass:color";@use 'roedl' as *;.PasswordResetLayout {
  max-width: 430px;

  .PasswordResetHeader {
    .PasswordResetTitle {
      color: $custom-grey;
      font-size: 1.5rem;
      font-weight: 600;
      margin-bottom: 1em;
    }

    .PasswordResetDescription {
      font-weight: 400;
      color: $custom-grey;
    }
  }
}

@use "sass:color";@use 'roedl' as *;.Todos {
  .tabs {
    .nav-item {
      .nav-link {
        cursor: pointer;
      }
    }
  }

  .no-todos {
    text-align: center;
  }
}

.react-tooltip-todo {
  max-width: 300px;
  white-space: initial;
}

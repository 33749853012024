@use "sass:color";@use 'roedl' as *;.ComponentListContainer {
  .component-list-container-footer {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 1.1rem 2.2rem;
    border-top: 1px solid $input-border-color;

    .footer-button {
      margin: 2.2rem 15px 0 15px;
    }
    .m-btn--disabled {
      background-color: $custom-light-grey;
      border: none;
      color: black;
      opacity: 0.5;
    }
  }
}

@use "sass:color";@use 'roedl' as *;.GuaranteeOfOriginCheckResult {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  gap: 15px;

  .check-result-icon {
    font-size: 30px;
    color: $brand-color;
  }
}

@use "sass:color";@use 'roedl' as *;.EnergyDataAcquisitionList {
  .select-all-checkbox {
    & + span {
      margin-top: -5px;
      margin-left: 1px;
      background-color: #fff !important;
    }
  }

  .list-item-checkbox {
    & + span {
      margin-top: -5px;
    }
  }

  .ReactTable {
    .rt-thead .rt-tr {
      text-align: left;
    }
  }

  .total {
    font-weight: bold;
  }

  .align-right {
    display: inline-block;
    width: 100%;
    text-align: right;
  }
}

@use "sass:color";@use 'roedl' as *;.Paragraph6ContractPage {
  .contracts-header {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  .contracts-body {
    margin-top: 30px;
  }
}

@use "sass:color";@use 'roedl' as *;.FormItems {
  .form-item {
    margin-bottom: 10px;
  }
  .label-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
}

.CreatableDropdown {
  display: flex;
  flex-flow: column;

  .controls {
    display: flex;
    align-items: center;

    .FormField {
      margin: 0 0.5rem 0 0;
      flex-grow: 1;
    }

    .create-button {
      align-self: flex-start;
    }

    .edit-button {
      align-self: flex-start;
      margin-left: 3px;
    }
  }

  .card {
    margin-top: 0.5rem;
  }
}

.DatepickerWithOverdueDates {
  display: flex;

  .Datepicker {
    flex-grow: 1;
    margin-right: 0.5rem;
  }

  .due-date-form-field {
    cursor: pointer;

    .m-checkbox {
      height: 16px;
      margin-top: -2px;
      margin-bottom: 0;
      pointer-events: none;
    }
  }
}

@use "sass:color";@use 'roedl' as *;.UploadableImageField {
  .upload-with-button {
    display: flex;
    flex-flow: column;
    align-items: flex-start;

    .uploaded-image {
      object-fit: contain;
      max-width: 700px;
      height: 200px;
      margin-bottom: 5px;
    }
  }

  .delete-logo {
    margin-left: 7px;
  }
}

@use "sass:color";@use 'roedl' as *;.documents-list {
  width: 100%;

  .controls {
    display: flex;
    margin-bottom: 1rem;

    button {
      margin-left: 3px;
    }

    .left-hand-controls {
      display: flex;
      align-items: center;
      flex-direction: row;
      .document-provided {
        margin: 0;
      }
      .document-provided-checkbox {
        margin-bottom: 10px;
      }
    }
  }

  .rt-td {
    display: flex;
    align-items: center;
  }

  .file-type {
    margin-right: 3px;
  }

  .icons {
    text-align: right;

    a {
      cursor: pointer;
    }

    .la {
      margin: 0 3px;
    }

    &.disabled {
      a {
        opacity: 0.5;
        pointer-events: none;
      }
    }
  }

  .rename-submit-button {
    margin-left: 3px;
  }
}

@use "sass:color";@use 'roedl' as *;.netzbezug-overview-container {
  width: 100%;
  min-height: calc(100vh - 440px);
}

.site-selector-wrapper {
  display: flex;
  height: 80%;
  align-items: center;
  justify-content: center;
}

@use "sass:color";@use 'roedl' as *;.RawEnergyDataSiteMeters {
  .meter-dropdown {
    flex-grow: 1;
  }

  .unit-dropdown {
    display: flex;
    align-items: center;
    gap: var(--mantine-spacing-sm);

    .mantine-Select-label {
      margin: 0;
      font-weight: bold;
    }

    .mantine-Select-input {
      width: 100px;
    }
  }
}

@use "sass:color";@use 'roedl' as *;#staff-view-toggle,
#search,
#optinode-help-icon {
  float: right;
  display: flex;
  align-items: center;
  height: 100%;
}

#optinode-help-icon {
  margin-left: 32px;
}

#search {
  margin-right: 16px;
}

#m_header_topbar {
  .UserMenu {
    margin: 0 20px 0 30px;
  }
}

@use "sass:color";@use 'roedl' as *;.ColorSelectionModal__colors {
  display: flex;
  justify-content: center;
  align-items: center;
  .ColorSelectionModal__color {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin: 10px;
    cursor: pointer;
    &.highlighted {
      border: 2px solid #000;
    }
  }
}

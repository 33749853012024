@use "sass:color";@use 'roedl' as *;.IndividualMixFields {
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 10px;
  border-bottom: $custom-very-light-grey 2px solid;
  .FormItemInline > .FormField2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .FormField2 {
    flex-grow: 1;
  }
}

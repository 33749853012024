@use "sass:color";@use 'roedl' as *;.AnlageStromstgList {
  margin-bottom: 30px;

  .section-header {
    margin-bottom: 10px;
  }

  .ReactTable {
    .rt-thead .rt-tr {
      text-align: left;
    }

    .rt-tfoot {
      color: #808080;
      font-weight: bold;
    }
  }

  .no-data-component {
    text-align: center;

    p {
      margin-top: 10px;
    }
  }

  .controls {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
  }
}

@use "sass:color";@use 'roedl' as *;.IconButton.iconcolor {
  &-brand .Icon {
    color: $brand-color;
  }

  &-danger .Icon {
    color: $danger-color;
  }

  &-info .Icon {
    color: $info-color;
  }

  &-primary .Icon {
    color: $primary-color;
  }

  &-secondary .Icon {
    color: $input-border-color;
  }

  &-warning .Icon {
    color: $warning-color;
  }
}

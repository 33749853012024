@use "sass:color";@use 'roedl' as *;.FileUpload {
  border: 1px dashed $custom-light-grey;
  margin: 1em auto;
  padding: 1.5em;
  display: flex;
  flex-flow: column;
  align-items: center;

  .upload-icon-container {
    .upload-icon {
      font-size: 35px;
      color: $custom-light-grey;
    }
  }

  .upload-button {
    margin: 15px 0 5px 0;
  }

  &.is-invalid {
    border-color: $danger-color;
  }

  &.is-valid {
    border-color: $success-color;
  }
}

@use "sass:color";@use 'roedl' as *;.EnergyDataView {
  .energydata-tabs {
    > .mantine-Tabs-list {
      margin-bottom: 20px;
      padding: 5px;
      background-color: white;

      .mantine-Tabs-tab {
        font-size: 15px;
      }
    }
  }

  .mantine-Tabs-tab[data-active] {
    font-weight: 400;
  }

  .dropzone-overlay-section {
    ul {
      list-style: none;

      .year {
        display: inline-block;
        min-width: 55px;
      }

      a {
        color: #fff;
      }
    }
  }
}

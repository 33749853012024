@use "sass:color";@use 'roedl' as *;.SpinButton {
  .button-content {
    position: relative;
    display: inline-block;
  }

  .spinner {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    text-align: center;
    vertical-align: center;
  }

  .spinner-icon {
    font-size: 2.5rem;
    margin-top: -0.6rem;
  }
}

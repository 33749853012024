@use "sass:color";@use 'roedl' as *;.MeterConfigurationButtons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 0.5rem;

  margin-bottom: 24px;
  padding-top: 4px;

  .MeterConfigurationActions {
    display: flex;
    column-gap: 0.5rem;
  }

  .LastUpdateText {
    color: $custom-grey;
  }

  .MeterAutomaticAssignWrapper {
    display: flex;
    align-items: center;
    column-gap: 6px;
  }
}

@use "sass:color";@use 'roedl' as *;.FormFieldLabel {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;

  .label-title-container {
    display: flex;
    align-items: center;
    flex-direction: row;
  }

  .label-title {
    padding-top: calc(0.65rem + 1px);
    padding-bottom: calc(0.65rem + 1px);
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.25;
  }

  .help-icon {
    margin-left: 10px;
  }
}

@use "sass:color";@use 'roedl' as *;.TablePagination {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 20px;
  margin: 12px 0;

  .table-pagination-btn {
    background-color: unset;
    border: unset;
    outline: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    transition: background-color 0.3s ease-in-out;

    &:not(:disabled):hover {
      cursor: pointer;
      background-color: $custom-light-grey;
    }
  }

  .table-pagination-range-container {
    display: flex;
    align-items: center;
    column-gap: 10px;

    .table-pagination-dots {
      width: 2px;
      height: 2px;
      border-radius: 50%;
      position: relative;
      background-color: $node-energy-black;
      margin: 0 3px;

      &::before {
        content: "";
        position: absolute;
        left: -5px;
        width: 2px;
        height: 2px;
        border-radius: 50%;
        background-color: $node-energy-black;
      }

      &::after {
        content: "";
        position: absolute;
        left: 5px;
        width: 2px;
        height: 2px;
        border-radius: 50%;
        background-color: $node-energy-black;
      }
    }

    .table-pagination-range-btn {
      background-color: unset;
      border: unset;
      outline: unset;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $node-energy-black;
      font-size: 1.1rem;
      font-weight: 500;
      width: 28px;
      height: 28px;
      border-radius: 50%;
      transition:
        color,
        background-color 0.3s ease-in-out;

      &:hover {
        cursor: pointer;
        color: white;
        background-color: $brand-color;
      }

      &__active {
        color: white;
        background-color: $brand-color;
      }
    }
  }

  .table-page-size-select,
  .table-page-input-field {
    .FormField {
      margin-bottom: 0;
    }
  }

  .table-page-input-field {
    width: 60px;
  }
}

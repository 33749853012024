@use "sass:color";@use 'roedl' as *;.TemplateIcon {
  margin-top: 1px;
  font-size: 15px;
  text-decoration: none;
  color: $primary-color;
  fill: $primary-color;
  cursor: pointer;
}

.TemplateIcon-active {
  width: 14px !important;
  margin-top: 4px;
  vertical-align: baseline;
}

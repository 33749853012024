@use "sass:color";@use 'roedl' as *;.VariantRename {
  .edit-icon {
    margin-left: 5px;
  }

  .VariantRenameForm {
    .inner-form {
      display: flex;
      align-items: center;

      .column-field {
        display: flex;
        margin-bottom: 0;
        margin-right: 5px;
        min-width: 150px;
        max-width: 70%;
      }

      label {
        padding: 0;
        margin-right: 3px;
      }

      button {
        margin-right: 3px;
      }
    }

    .errors {
      display: none;

      &.show {
        display: initial;
      }
    }
  }
}

@use "sass:color";@use 'roedl' as *;.col-header {
  font-size: 15px;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
}

.metering-concept-row {
  font-size: 15px;

  &:hover {
    background-color: $custom-very-light-grey;
  }

  div.col {
    padding-top: 0.3em;
    padding-bottom: 0.3em;
  }
}

.clickable {
  cursor: pointer;
}

.grey-cell {
  background: rgba(45, 47, 62, 0.04);
}

@use "sass:color";@use 'roedl' as *;.ContractDeliverySubComponent {
  margin-left: 40px;
  margin-right: 40px;

  .SwissArmyTable {
    margin-bottom: 30px;
  }

  .alert-warning-missing-deliveries-row {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }

  .contract-delivery-sub-component-checkbox {
    margin-left: 30px;
  }
}

@use "sass:color";@use 'roedl' as *;.TabBar {
  .tab-link {
    cursor: pointer;
  }
  .highlighted {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .orange-circle {
      margin-left: 7px;
      margin-right: 0px;
    }
  }
}

@use "sass:color";@use 'roedl' as *;.AddressFieldsContainer {
  display: flex;
  gap: 1.5em;

  .AddressFieldBox:nth-child(1) {
    flex: 3;
  }

  .AddressFieldBox:nth-child(2) {
    flex: 1;
  }
}

@use "sass:color";@use 'roedl' as *;.download-link {
  color: $primary-color;
  text-decoration: none;
  background-color: transparent;
  cursor: pointer;

  &:hover {
    color: #2739c1;
    text-decoration: underline;
  }
}

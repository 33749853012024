@use "sass:color";@use 'roedl' as *;.FormField {
  margin: 0 0 10px 0;

  .show {
    display: initial;
  }

  .hide {
    display: none;
  }
}

.form-field {
  .select2 {
    display: block;
  }
}

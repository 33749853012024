@use "sass:color";@use 'roedl' as *;.DeliveryModal {
  .ExtensionDuration,
  .CancellationDeadline,
  .DueDate {
    .fields {
      display: flex;

      .FormItem {
        .label-container {
          display: none;
        }

        #id_form_extensionDurationValue {
          width: 50px;
          height: 38px;
          margin-right: 10px;
        }

        #id_form_extensionDurationUnit {
          width: 200px;
        }

        #id_form_cancelationDeadlineValue {
          width: 50px;
          height: 38px;
          margin-right: 10px;
        }

        #id_form_cancelationDeadlineUnit,
        #id_form_cancelationReference,
        #id_form_dueDateReference {
          width: 200px;
        }
      }

      .text-between-fields {
        align-self: center;
        margin: 0 10px 10px 10px;
      }
    }
  }
}

@use "sass:color";@use 'roedl' as *;.EdaVisualizationModal {
  max-width: calc(100vw - 65px);
  padding: 0.5rem;

  @media screen and (min-width: 2065px) {
    max-width: 2000px;
  }

  .modal-body {
    max-height: calc(100vh - 220px);
    display: flex;
    flex-direction: column;
  }

  .DataAlert {
    margin-bottom: 2em;
  }
}

.modal-two-button-footer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

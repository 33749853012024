@use "sass:color";@use 'roedl' as *;.MonthYearRangePicker {
  display: flex;
  align-content: center;
  justify-content: center;

  .divider {
    align-self: center;
    margin-left: 10px;
    margin-right: 10px;
  }

  .react-select {
    min-width: 150px;
  }
}

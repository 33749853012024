@use "sass:color";@use 'roedl' as *;.PersonsWidget {
  .persons-header {
    padding: 0px 15px;

    &.no-margin {
      margin: 0;
      padding: 0;
    }
  }

  .persons-row {
    display: flex;
    flex-wrap: wrap;

    .PersonIcon {
      padding-right: 15px;
      padding-left: 15px;
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;

      .person-color-box {
        width: 2em;
        height: 2em;
        display: inline-block;
        border: none;
      }
    }
  }
}

@use "sass:color";@use 'roedl' as *;.EnergyDataQuality {
  .data-quality-percent {
    font-size: var(--mantine-font-size-lg);
    line-height: 1;
  }

  .data-quality-details {
    width: 50%;

    a {
      color: $primary-color;
    }

    button:focus {
      outline: 0;
    }
  }

  .data-quality-ok {
    margin-right: 0.5rem;
  }
}

// needs to be outside component wrapper class due to portal
.data-quality-dropdown a {
  color: $primary-color;
}

@use "sass:color";@use 'roedl' as *;.BasicConfirmationModal {
  .modal-title {
    display: flex;
    align-items: center;

    .icon {
      font-size: 25px;
      margin-right: 8px;
    }

    .error-icon {
      filter: saturate(200%);
    }

    .warning-icon {
      color: $warning-color;
    }
  }

  .footer-when-no-header {
    margin-top: 30px;
  }
}

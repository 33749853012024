@use "sass:color";@use 'roedl' as *;.ImportLogs {
  .load-more-button-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  .rt-resizable-header-content {
    text-align: left;
  }

  .no-data-component {
    text-align: center;

    p {
      margin-top: 10px;
    }
  }
}

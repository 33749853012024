@use "sass:color";@use 'roedl' as *;.CustomElementMenu {
  box-shadow: 0px 4px 4px 0px #00000040;
  right: 0;
  top: 0;
  width: 50px;
  border: 1px solid $custom-very-light-grey;
  background-color: white;

  .button-with-only-icon {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border: none;
    background: none;
  }

  .text-element-button {
    margin-top: 18px;
    img {
      width: 50px;
      &:hover {
        filter: invert(38%) sepia(87%) saturate(2460%) hue-rotate(220deg)
          brightness(93%) contrast(86%);
      }
    }
  }

  .box-element-button {
    margin-top: 20px;
    .box-element {
      width: 18px;
      height: 18px;
      border: 1px solid black;
      border-radius: 3px;
      &:hover {
        border: 2px solid $primary-color;
      }
    }
  }

  .download-graph-button {
    margin-top: 10px;
    margin-bottom: 10px;
    &:hover {
      color: $primary-color;
    }
  }
}

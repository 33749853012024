@use "sass:color";@use 'roedl' as *;.PerformanceMonitoringHeader {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1rem;

  .Dropdown {
    margin: 0;
    width: 80px;
  }
}

.NoDataComponent {
  text-align: center;
}

.PerformanceMonitoringOverviewDisplay {
  width: inherit;
}

.PerformanceMonitoringDateSelection {
  display: flex;
  align-items: center;
  gap: 1rem;

  .Dropdown {
    width: 120px;
  }
}

@use "sass:color";@use 'roedl' as *;.LoginForm {
  width: 300px;
  text-align: center;

  .LoginIconContainer {
    display: flex;
    justify-content: center;

    .LoginIcon {
      width: 120px;
      height: 120px;
      border-radius: 50%;
      background-color: #f7fbfb;
      border: 1px solid #dfe9eb;
      margin-bottom: 4em;
      position: relative;
      overflow: hidden;

      &::before {
        content: "";
        position: absolute;
        transform: translate(-50%, -50%);
        width: 52px;
        height: 52px;
        border-radius: 50%;
        background-color: #edf7f7;
        top: 46px;
        left: 50%;
      }

      &::after {
        content: "";
        position: absolute;
        transform: translate(-50%, -50%);
        width: 150px;
        height: 150px;
        border-radius: 50%;
        background-color: #edf7f7;
        left: 50%;
        bottom: -190px;
      }
    }
  }

  .LoginTitle {
    margin-bottom: 1.5em;
    color: $custom-grey;
    font-weight: 500;
    font-size: 16px;
    font-weight: 500;
  }

  .ForgotPassword {
    margin-top: 2em;
  }

  .LoginFilledBtn,
  .LoginOutlinedBtn {
    border-radius: 60px;
    width: 100%;
  }

  .LoginFilledBtn {
    margin-top: 16px;
  }

  .LoginOutlinedBtn {
    border: 1px solid $brand-color;
    background-color: inherit;
    color: $brand-color;
    margin-top: 12px;

    &:hover {
      background-color: $input-border-color !important;
      border: 1px solid $brand-color !important;
    }

    &:focus {
      color: $brand-color !important;
      border: 1px solid $brand-color !important;
    }
  }

  .login-error {
    margin-top: 20px;

    .LoginErrorText {
      margin-bottom: unset;
    }
  }
}

@use "sass:color";@use 'roedl' as *;.DateInput2 {
  &.is-invalid {
    input {
      border-color: $danger-color;
    }
  }

  &.warning {
    input {
      border-color: $warning-color;
    }
  }

  .date-picker-input-wrapper {
    flex-wrap: unset;
  }
}

@use "sass:color";@use 'roedl' as *;.dropzone-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 2.5em 2.5em;
  background: rgba(0, 0, 0, 0.7);
  text-align: center;
  color: #fff;
  z-index: 999;

  .dropzone-overlay-section {
    padding: 0.5em 3em;
    text-align: center;
    font-size: large;
  }
}
